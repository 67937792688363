import React, { useState } from 'react';
import {
  InputLabel, Select, MenuItem, FormControl, Button, Link, Snackbar, IconButton, InputBase,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
// import CachedIcon from '@mui/icons-material/Cached';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import logoSvg from '../resources/images/logo.png';
import { nativePostMessage } from '../common/components/NativeInterface';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: '3.75rem',
    borderRadius: '.475rem',
    backgroundColor: '#fff',
    marginRight: 'auto',
    marginLeft: 'auto',
    boxShadow: '0 .1rem 1rem .25rem rgba(0,0,0,.05)',
    '@media (min-width:992px)': {
      width: '600px',
    },
  },
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  extraContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  registerButton: {
    minWidth: 'unset',
  },
  resetPassword: {
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  formControl: {
    backgroundColor: '#f5f8fa',
    borderColor: '#f5f8fa',
    color: '#5e6278',
    transition: 'color .2s ease,background-color .2s ease',
    padding: '.825rem 1.5rem',
    borderRadius: '.625rem',
    width: '100%',
    border: '1px solid #e4e6ef',
  },
  textDark: {
    color: '#181c32',
    textAlign: 'center',
  },
  loginButton: {
    padding: 'calc(2rem + 1px)',
    backgroundColor: '#0095e8',
    borderRadius: '.625rem',
  },
  downloadButton: {
    padding: 'calc(1rem + 1px)',
    backgroundColor: '#f5f8fa',
    borderRadius: '.625rem',
    color: '#7e8299',
    textAlign: 'center',
    fontWeight: '600',
    cursor: 'pointer',
  },
  textInfo: {
    background: '#f8f5ff',
    borderRadius: '.475rem',
    padding: '2rem',
    marginBottom: '1.5rem',
    fontSize: '0.9rem',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');

  const registrationEnabled = useSelector((state) => state.session.server.registration);
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);
  const emailEnabled = useSelector((state) => state.session.server.emailEnabled);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
      });
      if (response.ok) {
        const user = await response.json();
        dispatch(sessionActions.updateUser(user));
        nativePostMessage('login');
        navigate('/');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handleSubmit(e);
    }
  };

  return (
    <LoginLayout>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img height="200" width="200" alt="CTracking" src={`${logoSvg}`} />
        </div>
        <h1 className={classes.textDark}>CTracking</h1>
        <div className={classes.textInfo}>
          Phần mềm định vị và ghi lại lịch trình di chuyển trên xe hơi được phát triển bởi
          <strong> Carplayvn.store</strong>
          <br />
          <h3 style={{ textAlign: 'center' }}>Vui lòng nhập mã thiết bị</h3>
        </div>
        <InputBase
          required
          className={classes.formControl}
          error={failed}
          placeholder={t('deviceCode')}
          label={t('deviceCode')}
          name="email"
          value={email}
          autoComplete="email"
          autoFocus={!email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={handleSpecialKey}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleSubmit(ev);
            }
          }}
        />
        <Button
          className={classes.loginButton}
          onClick={handleSubmit}
          onKeyUp={handleSpecialKey}
          variant="contained"
          color="secondary"
          disabled={!email}
          sx={{
            ml: 1,
            '&.MuiButtonBase-root:hover': {
              bgcolor: '#0095e8',
            },
          }}
        >
          {t('loginLogin')}
        </Button>
        <div className={classes.extraContainer}>
          {registrationEnabled && (
            <Button
              className={classes.registerButton}
              onClick={() => navigate('/register')}
              disabled={!registrationEnabled}
              color="secondary"
            >
              {t('loginRegister')}
            </Button>
          )}
          {languageEnabled && (
            <FormControl fullWidth>
              <InputLabel>{t('loginLanguage')}</InputLabel>
              <Select label={t('loginLanguage')} value={language} onChange={(e) => setLanguage(e.target.value)}>
                {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
              </Select>
            </FormControl>
          )}
        </div>
        {emailEnabled && (
          <Link
            onClick={() => navigate('/reset-password')}
            className={classes.resetPassword}
            underline="none"
            variant="caption"
          >
            {t('loginReset')}
          </Link>
        )}
      </div>
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </LoginLayout>
  );
};

export default LoginPage;
